<template >
  <div class="home">
    <!-- head -->
    <AppHead></AppHead>
    <!-- 图片横幅 -->
    <AppBanner></AppBanner>
    <div class="box">
      <!-- 中间 -->
      <div class="main ">
        <div class="message markdown-body"
             v-html="html"></div>
      </div>
      <!-- 右边 -->
      <div class="details">
        <!-- 博主头像区域 -->
        <AppBlogger></AppBlogger>
        <details2></details2>
        <details3></details3>
        <details4></details4>
      </div>

    </div>
  </div>
</template>

<script>
import { getBlogs } from '@/api/getBlogs'
import { marked } from 'marked'
import '@/styles/markdown-css.css'
import { mapActions, mapGetters } from 'vuex'
import '@/assets/iconfont/iconfont.css'
import 'mavon-editor/dist/css/index.css'
import AppBlogger from '@/components/AppBlogger.vue'
import AppBanner from '@/components/AppBanner.vue'
import AppHead from '@/components/AppHead.vue'
import details2 from '@/components/details2.vue'
import details3 from '@/components/details3.vue'
import details4 from '@/components/details4.vue'
export default {
  name: 'homeIndex',
  components: {
    AppBlogger,
    AppBanner,
    AppHead,
    details2,
    details3,
    details4
  },
  data () {
    return {
      isActive: false,
      html: '',
      markdown: '',
      id: '',
      test: {
        type: 0,
        num: 1,
        id: ''
      }
    }
  },
  methods: {
    ...mapActions(['main', 'getBlogs']),
    markdownChange () {
      this.html = marked.parse(this.markdown)
      console.log('运行')
      console.log(this.html)
    },
    getRouterData () {
      // 只是改了query，其他都不变
      this.id = this.$route.query.id
    }
  },
  computed: {
    ...mapGetters('main', ['getBlogsById']),
    Blogs: function () {
      return this.$store.state.main.Blogs
    }
  },
  created () {
    // 使本页id 为地址id
    this.getRouterData()
    this.getBlogsById(this.id) // 为blog全部信息
    this.markdown = this.getBlogsById(this.id)
    console.log(this.markdown)
    if (this.markdown !== null) {
      this.markdownChange()
    } else {
      this.test.id = this.id
      console.log(this.id)
      getBlogs(this.test.type, this.test.num, this.test.id).then((res) => {
        console.log(res.data[0].blog)
        this.markdown = res.data[0].blog
        this.markdownChange()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  cursor: url("../../assets/Cursors/01.png"), default;
}
/* 中间条信息 */
.box {
  top: 400px;
  display: flex;
  margin: 0 auto;
  width: 1240px;
  background-color: rgb(255, 255, 255);
  justify-content: space-between;
  border-radius: 5px;
  scroll-snap-align: center;
  .details {
    top: 40px;
    position: sticky;
    display: block;
    height: 1000px;
    width: 375px;
    background-color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .details2 {
      margin-bottom: 20px;
      border-radius: 5px;
      box-shadow: 0 0 5px 5px #d8d8d8;
      height: 200px;
      width: 100%;
      position: relative;
    }
    .details3 {
      margin-bottom: 20px;
      border-radius: 5px;
      box-shadow: 0 0 5px 5px #d8d8d8;
      height: 200px;
      width: 100%;
      position: relative;
    }
    .details4 {
      margin-bottom: 20px;
      border-radius: 5px;
      box-shadow: 0 0 5px 5px #d8d8d8;
      height: 200px;
      width: 100%;
      position: relative;
    }
  }
  .main {
    padding: 10px;
    display: block;
    width: 865px;
    background-color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    .message {
      width: 100%;
      border-radius: 6px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0 5px 5px #d8d8d8;
      padding: 20px;
      box-sizing: border-box;
    }
  }
}

.home {
  margin: 0;
  padding: 0;
  height: 100vh;
}
</style>
