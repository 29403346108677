<template>
  <div class="main">
    <div class="left">
      <!-- 文件树 -->
      <!-- upload -->
      <div class="upload"><el-upload class="upload-demo"
                   action="https://jsonplaceholder.typicode.com/posts/"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :file-list="fileList"
                   list-type="picture">
          <el-button size="small"
                     type="primary">点击上传</el-button>
          <div slot="tip"
               class="el-upload__tip">只能上传jpg/png文件,且不超过500kb</div>
        </el-upload></div>

    </div>
    <!-- 瀑布流布局 -->
    <div class="waterfull">
      <div class="v-waterfall-content"
           id="v-waterfall">
        <div v-for="(img, index) in waterfallList"
             :key="index"
             class="v-waterfall-item"
             :style="{top:img.top+'px',left:img.left+'px',width:waterfallImgWidth+'px',height:img.height}">
          <img :src="img.src"
               alt="">
          <p style="font-size: small;color: #666;margin: 4px;">{{img.title}}</p>
          <p style="font-size: x-small;color: #9e9e9e;margin: 4px;padding-bottom: 6px;">{{img.info}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/getList'
export default {
  name: 'v-waterfall',
  data () {
    // const tree =
    return {
      // 瀑布流数据
      waterfallList: [],
      list: [],
      imgArr: [
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/1.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/login.jpg'),
        ('http://web-ffisher.oss-cn-hangzhou.aliyuncs.com/signup.jpg')
      ],
      // waterfallImgWidth: 100,
      waterfallImgWidth: 200, // 每个盒子的宽度
      // waterfallImgCol: 5,// 瀑布流的列数
      waterfallImgCol: 3, // 瀑布流的列数
      waterfallImgRight: 10, // 每个盒子的右padding
      waterfallImgBottom: 10, // 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      fileList: [
        { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
        { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }]
      // JSON.parse(JSON.stringify(tree))
    }
  },
  async created () {
    // 触发入口
    for (let i = 0; i < this.imgArr.length; i++) {
      // this.imgList.push(this.imgArr[Math.round(Math.random() * 8)]);// 图片随机显示
      this.imgList.push(this.imgArr[i])
    }
  },
  // 开始请求列表
  async mounted () {
    this.calculationWidth()
    await this.getTheList()
  },
  methods: {
    // 计算每个图片的宽度或者是列数
    calculationWidth () {
      const domWidth = document.getElementById('v-waterfall').offsetWidth
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      // 初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol)
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0
      }
      this.imgPreloading()
    },
    // 图片预加载
    imgPreloading () {
      for (let i = 0; i < this.imgList.length; i++) {
        const aImg = new Image()
        aImg.src = this.imgList[i]
        aImg.onload = aImg.onerror = (e) => {
          const imgData = {}
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height
          imgData.src = this.imgList[i]
          imgData.title = '标题'// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）
          imgData.info = '详情说明：啦啦啦啦啦'// 说明文字
          this.waterfallList.push(imgData)
          this.rankImg(imgData)
        }
      }
    },
    // 瀑布流布局
    rankImg (imgData) {
      const {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        waterfallDeviationHeight
        // waterfallImgCol
      } = this
      const minIndex = this.filterMin()
      imgData.top = waterfallDeviationHeight[minIndex]
      imgData.left = minIndex * (waterfallImgRight + waterfallImgWidth)
      // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;// 不加文字的盒子高度
      waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom + 56// 加了文字的盒子高度，留出文字的地方（这里设置56px）
      // console.log(imgData)
    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin () {
      const min = Math.min.apply(null, this.waterfallDeviationHeight)
      return this.waterfallDeviationHeight.indexOf(min)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    // 获取图片列表信息
    async getTheList () {
      this.list = await getList
      console.log(this.list)
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  margin: 0 auto;
  width: 1240px;
  height: 97vh;
  background-color: #f2f6fc;
}
/* left */
.left {
  display: block;
  width: 93%;
  height: 97vh;
}
/* 上传侧 */
.upload {
  border: 2px solid pink;
  display: block;
  width: 85%;
  padding: 20px;
}
/* 瀑布 */
.waterfull {
  width: 100%;
  background-color: #e4e7ed;
}
.v-waterfall-content {
  /* 主要 */
  width: 100%;
  height: 97vh;
  position: relative;
  /* 次要：设置滚动条，要求固定高度 */
  overflow-y: auto;
}

.v-waterfall-item {
  /* 主要 */
  float: left;
  position: absolute;
}

.v-waterfall-item img {
  /* 主要 */
  /* width: auto;height: auto; */
  width: 90%;
  height: auto;
  /* 次要 */
  border-radius: 6px;
}
</style>
