import { upload } from '@/api/upload'
const state = {
  // 文章临时文件
  tmpArticle: {
    context: '',
    type: ''
  }
}
const mutations = {
  setTmpArticle (state, obj, type = 0) {
    state.tmpArticle.context = obj
    state.tmpArticle.type = type
    // id 为保存时的时间戳
    // 打印测试
    console.log(state.tmpArticle.context)
    console.log(state.tmpArticle.type)
    // 保存到缓存
    localStorage.setItem('tmpArticle', JSON.stringify(state.tmpArticle))
  },
  getTmpArticle (state) {
    if (state.tmpArticle.id === '') {
      state.tmpArticle = JSON.parse(localStorage.getItem('tmpArticle'))
    }
  }
}
const actions = {
  // 服务器上传Blog

  async updateBlog (context, type) {
    console.log('正在尝试上传')
    const res = await upload(context.state.tmpArticle.context, type)
    try {
      if (res.code === 1) {
        console.log('提交成功')
        return true
      } else {
        console.log('提交失败')
        return false
      }
    } catch {
      console.log('提交错误')
    }
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
