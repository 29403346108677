<template>
  <div v-if="visible"
       class="message">
    <!-- 弹窗的内容 -->
    <div class="message-content">
      <div class="testEmail">
        <input type="email"
               name="email"
               class="input"
               placeholder="E-MAIL"
               v-model="email">
        <button class="niceButton4"
                style=""
                @click="getCode"
                sendEmail>
          发送验证
        </button>
      </div>
      <br>
      <!-- 验证码输入框 -->
      <input type="text"
      @input="sureTheCode"
             name="code"
             ref="codeInput"
             class="input"
             :style="codeStyle"
             placeholder="验证码"
             v-model="code">
      <br>
      <textarea type="
             textarea"
                name="message"
                class="textarea"
                placeholder="MESSAGE"
                maxlength="100"
                v-model="message"></textarea>
      <br>
      <!-- 评论按键人机验证 -->

      <div class="buttonBox">
        <!-- 提交按键 点击触发提交函数-->
        <button class="niceButton"
                @click="submit"><span>提交</span></button>
        <!-- 关闭按钮 -->
        <button class="close-button niceButton"
                @click="closePopup"><span>关闭</span></button>
      </div>
      <Vcode :show="isShow"
             @success="onSuccess"
             @close="onClose" />
    </div>

  </div>
</template>
<script>
import { requestCode } from '../api/requestCode.js'
import { sureCode } from '../api/sureCode.js'
import Vcode from "vue-puzzle-vcode"
export default {
  data () {
    return {
      email: '321944218@qq.com',
      message: '',
      // 人机验证界面是否出现
      isShow: false,
      code: '',
      cookies:'',
      codeStyle
    }
  },
  components: {
    Vcode
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 窗口间函数调用
    closePopup () {
      this.$emit('close')
    },
    submit () {
      this.isShow = true
    },
    onSuccess (msg) {
      console.log('验证通过')
      this.isShow = false // 通过验证后，需要手动关闭模态框
      this.$emit('close')

    },
    onClose () {
      this.isShow = false
    },
    getCode(){
      requestCode(this.email) 
    },
    async sureTheCode(){
      if(this.code.length==4){
        console.log('发送验证请求');
        try {
          console.log("正在发送");
          const res = await (await requestCode(undefined,this.code))
          if(res.code==1){
            console.log('code right')
            // 输入正确 验证码框变为绿色
            this.$refs.codeInput.style.borderColor = 'green'
          }else{
            console.log('wrong code')
          }
        }catch (error) {
          console.error('请求失败', error);
        }   
      }
      
    }
  }
}
</script>
<style lang="less" scoped>
.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-content {
  background: #fff;
  width: 400px;
  height: 300px;
  padding: 20px;
  border-radius: 5px;
  .testEmail {
    height: 40px;
    display: flex;
    justify-content: center;
  }
  .input {
    margin: 0px;
    width: 276px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: dimgray;
  }
  
  .input:invalid {
    animation: justshake 0.3s forwards;
    color: red;
  }
  .textarea {
    margin: 0px;
    margin-top: 20px;
    width: 276px;
    padding: 12px;
    resize: none;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: dimgray;
  }
  .buttonBox {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .niceButton {
    display: inline-block;
    border-radius: 5px;
    background-color: #e4942b;
    border: none;
    color: #ffff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 18px;
    width: 100px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    vertical-align: middle;
  }
  .niceButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  .niceButton span::after {
    content: ">";
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .niceButton:hover span {
    padding-right: 30px;
  }
  .niceButton:hover span::after {
    opacity: 1;
    right: 0;
  }

  @keyframes justshake {
    25% {
      transform: translateX(5px);
    }

    50% {
      transform: translateX(-5px);
    }

    75% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX-(5px);
    }
  }
}

.close-button {
  margin-top: 10px;
}
</style>
