<template>
  <!-- 网站日志  -->
  <div class="details4">
    <h3 class="title">站主日志</h3>
    <div id="siteTime">本站已运行{{ diffDays }} 天<br> {{ diffHours }} 小时 {{ diffMinutes }} 分钟 {{ diffSeconds }} 秒</div>
  </div>
</template>

<script>
export default {
  name: 'details4',
  data () {
    return {
      diffYears: 0,
      diffDays: 0,
      diffHours: 0,
      diffMinutes: 0,
      diffSeconds: 0
    }
  },
  methods: {
    siteTime () {
      // 时间计算器
      var seconds = 1000
      var minutes = seconds * 60
      var hours = minutes * 60
      var days = hours * 24
      var years = days * 365
      var today = new Date()
      var todayYear = today.getFullYear()
      var todayMonth = today.getMonth() + 1
      var todayDate = today.getDate()
      var todayHour = today.getHours()
      var todayMinute = today.getMinutes()
      var todaySecond = today.getSeconds()
      var t1 = Date.UTC(2023, 12, 12, 16, 16, 16) // 建站开始时间
      // 建站开始时间
      // 2023年12月12日 16点16分16秒
      var t2 = Date.UTC(todayYear, todayMonth, todayDate, todayHour, todayMinute, todaySecond)
      var diff = t2 - t1
      var diffYears = Math.floor(diff / years)
      var diffDays = Math.floor(diff / days) // 总天数(未减去年数)
      var diffHours = Math.floor((diff - (diffYears * 365 + diffDays) * days) / hours)
      var diffMinutes = Math.floor((diff - (diffYears * 365 + diffDays) * days - diffHours * hours) / minutes)
      var diffSeconds = Math.floor((diff - (diffYears * 365 + diffDays) * days - diffHours * hours - diffMinutes * minutes) / seconds)
      // document.getElementById('siteTime').innerHTML = '已运行 ' + diffYears + ' 年  ' + diffDays + ' 天 ' + diffHours + ' 小时 ' + diffMinutes + ' 分钟 ' + diffSeconds + ' 秒'
      this.diffYears = diffYears
      this.diffDays = diffDays
      this.diffHours = diffHours
      this.diffMinutes = diffMinutes
      this.diffSeconds = diffSeconds
      // 更新时间
      // console.log(diffYears + '年' + diffDays + '天' + diffHours + '小时' + diffMinutes + '分钟' + diffSeconds + '秒')
    }
  },
  mounted () {
    window.setInterval(this.siteTime, 1000)
  }
}
</script>

<style lang="less" scoped>
.details4 {
  .title {
    color: rgb(80, 78, 78);
    margin: 0px;
    margin-bottom: 6px;
    padding-top: 30px;
    padding-left: 20px;
    top: 10px;
  }
  #siteTime {
    font-size: 19px;
    color: rgb(131, 129, 129);
    margin: 0px;
    margin-bottom: 5px;
    padding-left: 40px;
  }
}
</style>
