<template>
  <div class="details2">
    <h3 class="title">公告</h3>
    <h3 class="second-line content">{{notice['0']}}</h3>
    <h3 class="third-line content">{{notice['1']}}</h3>
    <h3 class="fourth-line content">{{notice['2']}}</h3>
    <h3 class="fifth-line content">{{notice['3']}}</h3>
  </div>
</template>

<script>
// 获取公告接口
import { postIp } from '@/api/postIp'
import { getNotice } from '@/api/getNotice'
export default {
  name: 'details2',
  data () {
    return {
      notice: {},
      // 储存函数时间戳
      lastTriggerTime: null
    }
  },
  methods: {
    // 公告接口实现
    getNotice () {
      getNotice().then(res => {
        this.notice = res.data
      })
    },
    ipPost () {
      const currentDate = new Date()
      const currentDay = currentDate.getDate()
      // 如果上一次触发日期不存在，或者当前日期与上一次触发日期不同
      // if (!this.lastTriggerDate || currentDay !== this.lastTriggerDate.getDate()) {
      if (!this.lastTriggerDate || currentDay !== this.lastTriggerDate.getDate()) {
        console.log('函数第一次触发或非同一天内触发')
        postIp(localStorage.getItem('Ip'), localStorage.getItem('cityname'))
        // 更新上一次触发日期为当前日期
        this.lastTriggerDate = currentDate
      }
    }
  },
  created () {
    this.getNotice()
    this.ipPost()
  },
  mounted () {
    // 获取客户端ip并打印
    const ip = localStorage.getItem('Ip')
    console.info(ip)
  }
}
</script>

<style lang="less" scoped>
.details2 {
  background-color: rgb(255, 255, 255);
  .title {
    color: rgb(250, 86, 86);
    margin: 0px;
    margin-bottom: 6px;
    padding-top: 30px;
    padding-left: 20px;
    top: 10px;
  }
  .content {
    font-size: 19px;
    color: rgb(131, 129, 129);
    margin: 0px;
    margin-bottom: 5px;
    padding-left: 40px;
  }
}
</style>
