<template>
  <div class="main">
    <!-- 根据计算属性中的blog数量进行渲染  点击时传参到另一组件 -->
    <div v-for="( item , index ) in Blogs"
         :key="item.id"
         v-slide-in
         @click="toBlog(item.id)"
        >
      <!-- 中间单条信息 -->
      <div class="contentMsg">
        <div class="picture">
          <!-- 单条图片 -->
          <img src="../assets/072811_hr1.png">
        </div>
        <!-- 文本区域 -->
        <div class="msg">
          <h3><span class="iconfont icon-star"
                  id="icon-star"></span>{{index +1}}.{{item.main}}MYBLOG</h3>
          <span id="title"
                v-text="getTitle(item.blog)"></span>
          <textarea readonly
                    rows="3"
                    id="briefContent"
                    v-text="getAfterTitle(item.blog)"></textarea>
          <hr>
        </div>
        <!-- 分页 -->

      </div>
    </div>
    <el-pagination small
                   layout="prev, pager, next"
                   :total="50"
                   :page-size="20"
                   v-model="currentPage" />
  </div>
</template>

<script>
import extractMarkdownTitle from '@/utils/markdown.js'
import { mapActions } from 'vuex'
import '@/assets/iconfont/iconfont.css'
export default {
  data () {
    return {
      currentPage: '1',
      // 博客列表状态
      BlogListStatus: {
        type: 0,
        num: 1,
        id: ''
      }
    }
  },
  methods: {
    ...mapActions('main', ['getBlogs']),
    // 获取md 文章标题
    getTitle (blog) {
      return extractMarkdownTitle.extractMarkdownTitle(blog)
    },
    getAfterTitle (blog) {
      return extractMarkdownTitle.extractContentAfterTitle(blog)
    }
    ,
    // 点击list时跳转到主文章
    toBlog (id) {
      this.$router.push({
        path: '/MainBlog', // 只是把query改了，其他都没变
        query: {
          id: id
        }
      })
    }
  },
  computed: {
    // 从store 获取文章
    Blogs: function () {
      return this.$store.state.main.Blogs
    }
  },
  // 开始时获取文章
  created () {
    this.getBlogs(this.BlogListStatus)
  }

}
</script>

<style lang='less' scoped>
.main {
  padding: 10px;
  display: block;
  width: 865px;
  background-color: #ffffff;
  box-sizing: border-box;
  .contentMsg {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
    height: 245px;
    width: 100%;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    box-shadow: 0 0 5px 5px #d8d8d8;
    .msg {
      width: 504px;
      overflow: hidden;
      background-color: rgb(255, 255, 255);
      height: 100%;
      width: 60%;
    }
    .picture {
      display: block;
      margin: 15px;
      border-radius: 10px;
      width: 320px;
      overflow: hidden;
    }
    img {
      width: 320px;
      height: 100%;
      object-fit: cover;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.03);
        /* 悬停放大倍率 */
      }
    }
    #icon-star {
      margin-top: 10px;
      font-size: 20px;
    }
    h3 {
      display: block;
      line-height: 15px;
      color: rgb(255, 167, 167);
      font-size: 20px;
      margin-top: 15px;
      margin-left: 5px;
    }

    #title {
      display: -webkit-box;
      width: 450px;
      margin-top: 16px;
      margin-left: 5px;
      padding-left: 10px;
      border-left: 3px solid pink;
      font-size: 25px;
      color: rgb(68, 68, 68);
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
    }
    #briefContent {
      padding-left: 10px;
      margin-top: 10px;
      margin-left: 5px;
      display: block;
      font-family: -moz-fixed;
      width: 450px;
      height: 60px;
      white-space: pre;
      white-space: pre-wrap;
      word-wrap: break-word;
      border: none;
      border-left: 3px solid pink;
      outline: none;
      resize: none;
      background: rgb(255, 255, 255);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 15px;
      color: rgb(131, 125, 125);
    }
    hr {
      margin: 20px 20px;
      border: none;
      height: 1px;
      background: rgb(236, 236, 236);
    }
  }
}
</style>
