<template>
  <!-- 评论区 -->
  <div class="details3">
    <h3 class="title">留言</h3>
    <button class="btn"
            @click="showPopup">测试按钮</button>
    <submitMassage :visible="popupVisible"
                   :content="popupContent"
                   @close="closePopup"></submitMassage>
    <div class="comment-area">
      <!-- 评论区内容 -->
      <div class="comment-content"></div>
    </div>
  </div>
</template>

<script>
import submitMassage from './submitMassage.vue'
export default {
  components: {
    submitMassage
  },
  name: 'details3',
  data () {
    return {
      popupVisible: false,
      popupContent: '这是一个弹窗'
    }
  },
  methods: {
    showPopup () {
      this.popupVisible = true
    },
    closePopup () {
      this.popupVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.details3 {
  .title {
    color: rgb(80, 78, 78);
    margin: 0px;
    margin-bottom: 6px;
    padding-top: 30px;
    padding-left: 20px;
    top: 10px;
  }
  .btn {
    position: absolute;
    left: 100px;
    top: 35px;
  }
  .comment-area {
    height: 80%;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;

    .comment-content {
      width: 80%;
      height: 300px;
      margin: 0 auto;
      border: 1px solid #8d4141;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .comment-area::-webkit-scrollbar {
    width: 4px;
  }
  .comment-area::-webkit-scrollbar-thumb {
    background: #ff7676; // 滑块颜色
    border-radius: 1px; // 滑块圆角
  }

  .comment-area::-webkit-scrollbar-thumb:hover {
    background: #f40; // 鼠标移入滑块变红
  }

  .comment-area::-webkit-scrollbar-track {
    border-radius: 10px; // 轨道圆角
    background-color: #fdbdff // 轨道颜色 ;
  }
}
</style>
