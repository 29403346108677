<template>
  <div>
    <!-- <div class="head"
         ></div> -->
    <mavon-editor aria-placeholder="开始编辑..."
                  v-model="context"
                  :toolbars="toolbars"
                  @change="changeFn"
                  @save="save(context)"
                  language="zh"
                  navigation
                  ishljs
                  id="markDown" />

    <el-button id="submit"
               @click="save(context)">保存</el-button>
    <el-button id="submit"
               @click="submit">提交</el-button>

  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import 'mavon-editor/dist/css/index.css'
export default {
  name: 'demoMarkDown',
  data () {
    return {
      context: '', // 输入的数据
      html: '',
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      }
    }
  },
  methods: {
    ...mapMutations('upload', ['setTmpArticle', 'getTmpArticle']),
    ...mapActions('upload', ['updateBlog']),
    // 编辑区域内容改变
    changeFn (value, render) {
      console.log(value, render)
      this.html = render
    },
    submit () {
      this.setTmpArticle(this.context)
      this.updateBlog()
    },

    save () {
      this.setTmpArticle(this.context)
      console.log(this.context)
      console.log('')
    }
  },

  mounted () {
    // 打开界面时加载已经保存的文本
    this.getTmpArticle()
    console.log('缓存读取完成')
    this.context = this.tmpArticle.context
    console.log(this.tmpArticle.context)
    console.log('加载完成')
  },
  computed: {
    tmpArticle: function () {
      return this.$store.state.upload.tmpArticle
    }
  }
}
</script>

<style>
#markDown {
  margin: 0 auto;
  margin-bottom: 20px;
  height: 700px;
  width: 1240px;
}
#submit {
  display: block;
  width: 1240px;
  margin: 0 auto;
}
</style>
