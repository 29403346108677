// export default {
//   extractMarkdownTitle (markdownText) {
//     if (!markdownText) return '标题获取错误'
//     const regex = /^#\s+(.+)/
//     const match = markdownText.match(regex)
//     if (match && match.length > 1) {
//       return match[1].replace(/#/g, '') // 提取标题文本
//     }
//     return '标题获取错误' // 如果没有匹配到标题，返回null或者其他你希望的默认值
//   }
// }
export default {
  extractMarkdownTitle (markdownText) {
    // 确保传入的 markdownText 不是 undefined 或 null
    if (!markdownText) return '标题获取错误'

    const regex = /^#\s+(.+)/
    const match = markdownText.match(regex)
    if (match && match.length > 1) {
      const titleWithHash = match[1] // 包含 # 的标题文本
      const titleWithoutHash = titleWithHash.replace(/# /g, '') // 去除 # 号
      return titleWithoutHash // 返回去除 # 号后的标题文本
    }
    return '标题获取错误' // 如果没有匹配到标题，返回错误信息
  },
  extractContentAfterTitle (markdownText) {
    if (!markdownText) return '未提供Markdown文本'

    const regex = /^#\s+(.+)\s*\n\s*\n([\s\S]+?)(?=\n\n#|$)/ // 匹配标题和后续内容，直到下一个标题或文本结束
    const match = markdownText.match(regex)

    if (match && match.length > 2) {
      const contentAfterTitle = match[2] // 提取标题后的内容
      return contentAfterTitle.trim().replace(/#+|(\*+)/g, '') // 返回并去除首尾空白
    }
    return '内容获取错误' // 如果没有匹配到标题和内容，返回错误信息
  }
}
