import request from '@/utils/request'

// post 请求验证码
export const requestCode = (email,code,cookies) => {
  return request.get('/getCode', {
    withCredentials: true, // 允许携带 cookie
    params: {
      email: email, // 类型
      code: code
    },
    headers: {
      'Cookies': cookies
    }
  })
}
