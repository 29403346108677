<template>
  <!-- banner 背景板 -->
  <div class="banner"
       style="width: 100%;height:700px">
    <img v-bind:src="bannerImg"
         alt=""
         style="height: 100% ; width: 110%;object-fit: cover;">
    <div class="water-group">
      <div class="water water1"
           :style="{'background-image': `url(${require('@/assets/wave.png')})`}"></div>
      <div class="water water2"
           :style="{'background-image': `url(${require('@/assets/wave.png')})`}"></div>
      <div class="water water3"
           :style="{'background-image': `url(${require('@/assets/wave.png')})`}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bannerImg: this.$globalImg.bannerImg
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  background-attachment: fixed;
  overflow: hidden;
  .water-group {
    position: relative;
    top: -110px;
    height: 110px;
    width: 110%;
    overflow: hidden;
    background: linear-gradient(to bottom, transparent, #f4f5f5);
    .water {
      position: absolute;
      width: 200%;
      height: 100%;
      background-size: 50% 100%;
    }
    .water1 {
      top: 20px;
      left: -100%;
      opacity: 0.2;
      animation: water-right 20s infinite linear;
    }
    .water2 {
      top: 30px;
      left: 0;
      opacity: 0.3;
      animation: water-left 30s infinite linear;
    }
    .water3 {
      top: 45px;
      left: -100%;
      animation: water-right 40s infinite linear;
    }
    @keyframes water-right {
      0% {
        transform: translateX(0) translateZ(0) scaleY(1);
      }
      50% {
        transform: translateX(25%) translateZ(0) scaleY(0.85);
      }
      100% {
        transform: translateX(50%) translateZ(0) scaleY(1);
      }
    }
    @keyframes water-left {
      from {
        transform: translate(0%, 0px);
      }
      to {
        transform: translate(-50%, 0px);
      }
    }
  }
}
</style>
