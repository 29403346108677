import { render, staticRenderFns } from "./details4.vue?vue&type=template&id=511a0162&scoped=true"
import script from "./details4.vue?vue&type=script&lang=js"
export * from "./details4.vue?vue&type=script&lang=js"
import style0 from "./details4.vue?vue&type=style&index=0&id=511a0162&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511a0162",
  null
  
)

export default component.exports