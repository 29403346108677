<template>
  <div class="details1">
    <div class="img"
         v-bind:style="{ backgroundImage: 'url(' + this.$globalImg.bloggerImg + ')' }"></div>
    <div class="headImg"></div>
    <div class="dim"></div>
    <div class="follow"
         @click="followMe">

      <!-- bilibili图标svg -->
      <svg t="1697209119114"
           class="icon"
           viewBox="0 0 1024 1024"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           p-id="2146"
           id="mx_n_1697209119114"
           width="25"
           height="25">
        <path d="M777.514667 131.669333a53.333333 53.333333 0 0 1 0 75.434667L728.746667 255.829333h49.92A160 160 0 0 1 938.666667 415.872v320a160 160 0 0 1-160 160H245.333333A160 160 0 0 1 85.333333 735.872v-320a160 160 0 0 1 160-160h49.749334L246.4 207.146667a53.333333 53.333333 0 1 1 75.392-75.434667l113.152 113.152c3.370667 3.370667 6.186667 7.04 8.448 10.965333h137.088c2.261333-3.925333 5.12-7.68 8.490667-11.008l113.109333-113.152a53.333333 53.333333 0 0 1 75.434667 0z m1.152 231.253334H245.333333a53.333333 53.333333 0 0 0-53.205333 49.365333l-0.128 4.010667v320c0 28.117333 21.76 51.157333 49.365333 53.162666l3.968 0.170667h533.333334a53.333333 53.333333 0 0 0 53.205333-49.365333l0.128-3.968v-320c0-29.44-23.893333-53.333333-53.333333-53.333334z m-426.666667 106.666666c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z m320 0c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z"
              p-id="2147"
              fill="#FAF0E6"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    followMe () {
      window.open('https://space.bilibili.com/352113238?spm_id_from=333.1007.0.0', '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.details1 {
  border-radius: 5px;
  box-shadow: 0 0 5px 5px #d8d8d8;
  height: 200px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  .img {
    height: 100px;
    width: 100%;
    background-image: url();
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .headImg {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-image: url('../assets/MyHeadImg.png');
    background-repeat: no-repeat;
    background-size: 110% 110%;

    background-position: center;
    position: absolute;
    left: 145px;
    top: 65px;
    z-index: 999;
  }
  .dim {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 140px;
    top: 60px;
    background-color: rgba(255, 255, 255, 0.596);
  }
  .follow {
    width: 70px;
    height: 35px;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 5px;
    background-color: rgb(255, 211, 219);
    display: flex;
    /*主轴上的对齐方式为居中*/
    justify-content: center;
    /*交叉轴上对齐方式为居中*/
    align-items: center;
  }
}
</style>
