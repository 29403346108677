import request from '@/utils/request'

// post IP地址
export function postIp (ip, location) {
  request.post('/postIP', {
    ip: ip,
    location: location
  }
  )
}
