<template >

  <body>
    <object data="http://www.timelysnow.com.cn/bluemagicebook/"
            type="text/html"
            width="100%"
            height="1200px"
            id="bluemagicebook"
            class="bluemagicebook"></object>
  </body>
</template>

<script>
export default {
  name: 'Bluemagicebook',
  components: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () { },
  mounted () { },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
