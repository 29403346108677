import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'
// 引入 mavon-editor
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// 自定义指令
import vSlideln from './utils/vSlideln'
import less from 'less'
// 全局图片变量
import globalImg from './api/global-img'
Vue.prototype.$globalImg = globalImg
// 挂载全局
Vue.use(less)
Vue.directive('slide-in', vSlideln)
Vue.use(mavonEditor)
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
