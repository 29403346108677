import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login'
import Sinner from '@/views/sinner'
import Home from '@/views/home'
import SignUp from '@/views/signUp'
import UpLoad from '@/views/upLoad'
import Gallery from '@/views/gallery'
import Blogs from '@/views/blogs'
import Bluemagicebook from '@/views/bluemagicebook'


// import Loading from '@/components/loading'

Vue.use(VueRouter)
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

// call与apply：相同点：都可以修改this,
// 不同点：传递的参数，call传递多个参数时，以，作为分隔
//    apply传递多个参数时，以数组方式进行

// 重写push/replace
// 第一个参数：告诉原来push方法，你往哪里跳转（传递哪些参数）
// 第二个参数：成功的回调
// 第二个参数：失败的回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}

VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requireAuth: false
    },
    children:[
{
    path: '/',
    name: 'AppBlogList',
    meta: {
      requireAuth: false
     },
     component: () => import('@/components/AppBlogList.vue')
},
{path: '/MainBlog',
    name: 'MainBlog',
    meta: {
      requireAuth: false
    },
    component:()=>import('@/components/MainBlog')
    }

    ]
    ,
    component: Home
  },
  // {
  //   path: '/',
  //   name: 'AppBlogList',
  //   meta: {
  //     requireAuth: false
  //   },
  //   component: () => import('@/components/AppBlogList.vue')
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      requireAuth: false
    },
    component: Login
  },
  {
    path: '/sinner',
    name: 'sinner',
    meta: {
      requireAuth: false
    },
    component: Sinner
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      requireAuth: false
    },
    component: Home
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      requireAuth: false
    },
    component: SignUp
  }, {
    path: '/upload',
    name: 'UpLoad',
    meta: {
      requireAuth: true
    },
    component: UpLoad
  }, {
    path: '/gallery',
    name: 'Gallery',
    meta: {
      requireAuth: false
    },
    component: Gallery
  }, {
    path: '/blogs',
    name: 'Blogs',
    meta: {
      requireAuth: false
    },
    component: Blogs
  }
  , {
    path: '/Bluemagicebook',
    name: 'Bluemagicebook',
    meta: {
      requireAuth: false
    },
    component: Bluemagicebook
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
    // login 接口已经将jwt作为请求头
    if (localStorage.getItem('token') == null) {
      next('/login')
    } else {
      const res = await login()
      if (res.code === 1) { // 查询本地存储信息是否已经登陆
        next()
      } else {
        // if (to.path === '/upload') { // 如果是登录页面路径，就直接next()
        next('/login')
        // } else { // 不然就跳转到登录；
        //   next()
        // }
      }
    }
  } else {
    next()
  }
})
export default router
