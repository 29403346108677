<template>
<div class="main ">
        <div class="message markdown-body"
             v-html="html"></div>
      </div>  
</template>

<script>
import { getBlogs } from '@/api/getBlogs'
import { marked } from 'marked'
import '@/styles/markdown-css.css'
import { mapActions, mapGetters } from 'vuex'
import '@/assets/iconfont/iconfont.css'
import 'mavon-editor/dist/css/index.css'

export default {
  name: 'MainBlog',
  components: {
  },
  data () {
    return {
      isActive: false,
      html: '',
      markdown: '',
      id: '',
      test: {
        type: 0,
        num: 1,
        id: ''
      }
    }
  },
  methods: {
    ...mapActions(['main', 'getBlogs']),
    markdownChange () {
      this.html = marked.parse(this.markdown)
      console.log('运行')
      console.log(this.html)
    },
    getRouterData () {
      // 只是改了query，其他都不变
      this.id = this.$route.query.id
    }
  },
  computed: {
    ...mapGetters('main', ['getBlogsById']),
    Blogs: function () {
      return this.$store.state.main.Blogs
    }
  },
  created () {
    // 使本页id 为地址id
    this.getRouterData()
    this.id = this.$route.query.id
    this.getBlogsById(this.id) // 为blog全部信息
    this.markdown = this.getBlogsById(this.id)
    console.log(this.markdown)
    if (this.markdown !== null) {
      this.markdownChange()
    } else {
      console.log("未在本地查询到blogs");
      this.test.id = this.id
      console.log(this.id)
      getBlogs(this.test.type, this.test.num, this.test.id).then((res) => {
        console.log(res.data[0].blog)
        this.markdown = res.data[0].blog
        this.markdownChange()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
/* 中间条信息 */
.box {
  .main {
    padding: 10px;
    display: block;
    width: 865px;
    background-color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    .message {
      width: 100%;
      border-radius: 6px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0 5px 5px #d8d8d8;
      padding: 20px;
      box-sizing: border-box;
    }
  }
}

.home {
  margin: 0;
  padding: 0;
  height: 100vh;
}
</style>