<template >
  <div class="home">
    <!-- head -->
    <AppHead></AppHead>
    <!-- 图片横幅 -->
    <AppBanner></AppBanner>
    <div class="box">
      <!-- 中间 -->
      <keep-alive include="AppBlogList">
       <router-view></router-view>
      </keep-alive>
     
      <!-- <MainBlog></MainBlog> -->
      <!-- <AppBlogList></AppBlogList> -->
      <!-- 右边 -->
      <div class="details">
        <!-- 博主头像区域 -->
        <AppBlogger></AppBlogger>
        <details2></details2>
        <!-- <details3></details3> -->
        <details4></details4>
        <div class="details5">
          <P>Copyright © 2023</P>
          <P>闲渔博客 | By 闲渔</P>
          <p><a href="https://beian.miit.gov.cn/" target="_blank">
鲁ICP备2024097622号</a></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import '@/assets/iconfont/iconfont.css'
import AppBlogger from '@/components/AppBlogger.vue'
import AppBanner from '@/components/AppBanner.vue'
import AppHead from '@/components/AppHead.vue'
import MainBlog from '@/components/MainBlog.vue'
import AppBlogList from '@/components/AppBlogList.vue'
import details2 from '@/components/details2'
import details3 from '@/components/details3'
import details4 from '@/components/details4'
export default {
  name: 'homeIndex',
  components: {
    AppBlogger, // 个人头像配置区域
    AppBanner, // 背景板区域
    AppHead, // 网页头部导航区域
    AppBlogList, // 中心列表部位
    details2, // 右侧信息区域
    details3, // 评论区域
    details4,// 站主日志
    MainBlog // 博客
  },
  data () {
    return {
    }
  },
  methods: {
  }
}

</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  cursor: url("../../assets/Cursors/01.png"), default;
}
/* 按钮 */
#gallery {
  #write {
    width: 240px;
    height: 80px;
    color: rgb(142, 172, 142);
  }
}
/* 中间条信息 */

.box {
  top: 400px;
  display: flex;
  margin: 0 auto;
  width: 1240px;
  background-color: rgb(255, 255, 255);
  justify-content: space-between;
  border-radius: 5px;
}
.details {
  top: 40px;
  position: sticky;
  display: block;
  height: 100%;
  width: 375px;
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  .details2 {
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px #d8d8d8;
    height: 200px;
    width: 100%;
    position: relative;
  }
  .details3 {
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px #d8d8d8;
    height: 600px;
    width: 100%;
    position: relative;
  }
  .details4 {
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px #d8d8d8;
    height: 200px;
    width: 100%;
    position: relative;
  }
  .details5 {
    margin-bottom: 20px;
    border-radius: 5px;
    height: 100px;
    width: 100%;
    position: relative;
  }
  p {
    font-size: 15px;
    color: rgb(131, 125, 125);
    text-align: center;
  }
}

.home {
  margin: 0;
  padding: 0;
  height: 100vh;
}
</style>
