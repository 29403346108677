import request from '@/utils/request'
// 登录有关API调用
export const getBlogs = (type, num, id) => {
  return request.get('/getBlogs', {
    params: {
      type: type, // 类型
      num: num, // 数量
      id: id // id
    }
  })
}
