import { render, staticRenderFns } from "./details2.vue?vue&type=template&id=677f5112&scoped=true"
import script from "./details2.vue?vue&type=script&lang=js"
export * from "./details2.vue?vue&type=script&lang=js"
import style0 from "./details2.vue?vue&type=style&index=0&id=677f5112&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677f5112",
  null
  
)

export default component.exports