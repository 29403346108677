export default {
  namespaced: true,
  state () {
    return {
      // 个人权证
      userInfo: {
        token: ''
      }
    }
  },
  getters: {

  },
  mutations: {
    setUserInfo (state, obj) {
      state.userInfo.token = obj
      localStorage.setItem('token', obj)
    }
  },
  actions: {

  }

}
