import { getBlogs } from '@/api/getBlogs'
export default {
  namespaced: true,

  state () {
    return {
      // 全部内容
      Blogs: []
    }
  },
  getters: {
    getMainMassage (state) {
      return state.Blogs
    },
    getBlogsById: (state) => (id) => {
      try { return state.Blogs.filter(item => item.id == id)[0].blog } catch { return null }
      
    }
  },
  mutations: {
    // 在state中存入Blog
    changeBlogs (state, newBlogs) {
      state.Blogs = newBlogs
    }

  },
  actions: {
    // 获取blog信息
    async getBlogs (context, obj) {
      const newBlogs = await getBlogs(obj.type, obj.num, obj.id = '')
      context.commit('changeBlogs', newBlogs.data)
    }
  }
}
