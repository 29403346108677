<template>
  <div :class="[ {head: true} , { headActive:isActive}]"
       ref="head">
    <div class="headMain">
      <img src="../assets/FFisher.png"
           alt="FFISHER">
      <li class="menu1">
        <div class="nav-tab">更多</div>
        <ul class="nav-menu">
          <li @click="writeClick">写</li>
          <li @click="galleryClick">图</li>
          <li @click="getBlogs(test)">测试按钮</li>
          <li @click="toBluemagicebook">Bluemagicebook</li>
          <!-- <li @click="toAlist">alist</li> -->
          <li>链接5</li>
        </ul>
      </li>
      <div class="userInfo">
        <img id="headIcon"
             src="../assets/076960_hr1(180 180).png"
                   >
        <span id="username">username</span>
        <span id="lever">lv6</span>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isActive: false,
      test: {
        type: 0,
        num: 1,
        id: 111
      }
    }
  },
  methods: {
    ...mapActions('main', ['getBlogs']),
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if (scrollTop >= 700) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    // 跳转upload界面
    writeClick () {
      this.$router.push('/upload')
    },
    galleryClick () {
      this.$router.push('/gallery')
    },
    testClick () {

    },
    toAlist () {
      window.open('http://www.ffisher.top:5244/', '_blank')
    },
    toBluemagicebook(){
      this.$router.push('/Bluemagicebook')
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () { // 在加载时添加了scroll监听，如果不销毁，到下一个页面时，没有主动销毁，会报错'offsetTop' of undefined。因此，需要在页面跳转前销毁此监听
    window.removeEventListener('scroll', this.menu)
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.head {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  .headMain {
    display: flex;
    margin: 0 auto;
    width: 1240px;
    // 二级菜单
    .menu1 {
      margin-left: 700px;
      list-style-type: none; //去掉标签默认的左边符号
      float: left;
      width: 104px;
      height: 40px;
      // background-color: rgba(252, 250, 255, 0.5);
      cursor: pointer; // 鼠标移入导航栏变小手
      .nav-tab {
        height: 40px;
        width: 104px;
        margin: 0 auto;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        color: #000000;
        line-height: 40px;
        font-weight: 400;
        overflow: hidden;
        box-sizing: border-box;
        border-bottom: 0px solid #a0c6e9;
      }
      .nav-menu {
        width: 104px;
        height: 0px;
        float: left;
        background: #ffffff00;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0 0 2px 2px;
        overflow: hidden; // 让li的内容隐藏，实现从上往下显示的效果
        li {
          background-color: rgba(255, 255, 255, 0.6);
          // margin-left: 8px;
          width: 104px;
          height: 40px;
          margin-top: 2px;
          font-size: 14px;
          line-height: 40px;
          text-align: center;
          font-weight: 400;
          color: #0c0000;
        }
      }
    }
    li:hover {
      .nav-tab {
        border-bottom: 3px solid #a0c6e9; // 鼠标悬停时tab的底边框样式，以及下方的字体颜色
        color: #9dbcf0;
      }
      .nav-menu {
        transition: all 0.3s; // 鼠标移出时menu瞬间消失，故将transition加在hover里
        height: 215px; // 使menu的高度从0到275px，配合transition实现平滑滑下的动画效果
      }
    }
    // 顶部user信息
    .userInfo {
      display: flex;
      width: 240px;
      height: 40px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.1);
      span {
        margin-left: 20px;
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 20px;
      }
      #headIcon {
        margin-top: 0;
        margin-left: 10PX;
      }
      #username {
        white-space: nowrap;
        /*强制单行显示*/
        text-overflow: ellipsis;
        /*超出部分省略号表示*/
        overflow: hidden;
        /*超出部分隐藏*/
        width: 80px;
        /*设置显示的最大宽度*/
      }
      #lever {
        font-weight: 900;
        color: red;
      }
    }
  }
}
.headActive {
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: rgba(252, 250, 255, 0.5);
  transform: 1s;
}
</style>
